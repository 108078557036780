<template>
	<!-- 交易通绑卡 -->
	<div v-loading="loading" :class="themeClass" class="contBox width-fill bg-fff margin-t-10">
		<div class="form-Box">
			<div class="title flex-row-align-center text-center">
				<i class="iconfont icon-biaodan font-size30"></i>
				<div class="font-size16">{{ title }}</div>
			</div>
			<el-form :model="bindingform" :rules="rules" ref="bindingform" label-width="150px" class="demo-ruleForm">
				<div class="itemBoxtit">企业信息</div>
				<div class="itemBox">
					<div class="itemColor"></div>
					<el-form-item v-if="item.rule.fAnnexTypeID == '502'" v-for="(item, index) in uploadRule"
						:key="index" :label="item.rule.fRuleName" class='testClass'
						:required="item.rule.fIsNecessary == 1 ? true : false">
						<div class="padding-10 border-F2F2F2" style="margin-right: 35px;">
							<UploadImgList :rules="item.rule" :ISconfirm="ISconfirm" :uploadToken="uploadToken[index]"
								class="uploadimglist" :limit="1" :accept="limitTypeArr[index]"
								v-model="imgListName[index]" uploadType="image" @success="uploadSuccess" />
						</div>
						<div class="font-size12 font-color-FF9900 margin-t-4" style="line-height: normal;"
							v-if="limitTypeArr[index]||item.rule.fMaxSize">
							* 提示：
							<span>格式为{{item.rule.fFileType}}</span>
							<span v-if="item.rule.fMaxSize">,大小不超过{{ item.rule.fMaxSize }}MB</span>
						</div>
					</el-form-item>
					<el-form-item label="企业名称" prop="fCustomerName">
						<el-input v-model.trim="bindingform.fCustomerName" disabled placeholder="请输入企业名称"></el-input>
					</el-form-item>
					<el-form-item label="统一社会信用代码" prop="fIdentityCode">
						<el-input v-model.trim="bindingform.fIdentityCode" disabled
							placeholder="请输入统一社会信用代码"></el-input>
					</el-form-item>
					<el-form-item label="企业注册地址" prop="fAddress">
						<el-input v-model.trim="bindingform.fAddress" disabled placeholder="请输入企业注册地址"></el-input>
					</el-form-item>
					<el-form-item label="企业实际办公地址" prop="fCompanyAdress">
						<el-input v-model.trim="bindingform.fCompanyAdress" placeholder="请输入企业实际办公地址"></el-input>
						<p style="color: #FF9900; line-height: 16px; padding-right: 35px;">*与营业执照企业地址一致,如需修改,请自行编辑更改</p>
					</el-form-item>
					<el-form-item label="企业联系电话" prop="fCompanyPhone">
						<el-input v-model.trim="bindingform.fCompanyPhone" placeholder="请输入企业联系电话"></el-input>
					</el-form-item>
					<el-form-item label="是否招行贷款客户" prop="fIsCredit">
						<el-radio-group v-model="bindingform.fIsCredit">
							<el-radio :label="1">是</el-radio>
							<el-radio :label="0">否</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="是否上市公司" prop="fIsPublicCompany">
						<el-radio-group v-model="bindingform.fIsPublicCompany">
							<el-radio :label="1">是</el-radio>
							<el-radio :label="0">否</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="机构类型" prop="fOrgTypeID">
						<el-select v-model="bindingform.fOrgTypeID" placeholder="请选择机构类型">
							<el-option v-for="item in fOrgTypes" :key="item.fOrgTypeID * 1" :label="item.fOrgTypeName"
								:value="item.fOrgTypeID * 1">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="所有制性质" prop="fOwnershipID">
						<el-select v-model="bindingform.fOwnershipID" placeholder="请选择机构类型">
							<el-option v-for="item in fOwnerships" :key="item.fOwnershipID * 1"
								:label="item.fOwnershipName" :value="item.fOwnershipID * 1">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="企业规模" prop="fEnterpriseSize">
						<el-select v-model="bindingform.fEnterpriseSize" placeholder="请选择机构类型">
							<el-option v-for="item in fEnterpriseSizes" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
				</div>

				<div class="itemBoxtit">成员信息</div>
				<div class="itemBox">
					<div class="itemColor"></div>
					<el-form-item v-if="item.rule.fAnnexTypeID == '213'" v-for="(item, index) in uploadRule"
						:key="index" :label="item.rule.fRuleName" class='testClass'
						:required="item.rule.fIsNecessary == 1 ? true : false">
						<div class="padding-10 border-F2F2F2" style="margin-right: 35px;">
							<UploadImgList :rules="item.rule" :ISconfirm="ISconfirm" :uploadToken="uploadToken1[index]"
								class="uploadimglist" :limit="1" :accept="limitTypeArr1[index]"
								v-model="imgListName[index]" uploadType="image" @success="uploadSuccess1" />
						</div>
						<div class="font-size12 font-color-FF9900 margin-t-4" style="line-height: normal;"
							v-if="limitTypeArr1[index]||item.rule.fMaxSize">
							* 提示：
							<span>格式为{{item.rule.fFileType}}</span>
							<span v-if="item.rule.fMaxSize">,大小不超过{{ item.rule.fMaxSize }}MB</span>
						</div>
					</el-form-item>
					<el-form-item v-if="item.rule.fAnnexTypeID == '214'" v-for="(item, index) in uploadRule"
						:key="index" :label="item.rule.fRuleName" class='testClass'
						:required="item.rule.fIsNecessary == 1 ? true : false">
						<div class="padding-10 border-F2F2F2" style="margin-right: 35px;">
							<UploadImgList :rules="item.rule" :ISconfirm="ISconfirm" :uploadToken="uploadToken1[index]"
								class="uploadimglist" :limit="1" :accept="limitTypeArr1[index]"
								v-model="imgListName[index]" uploadType="image" @success="uploadSuccess1" />
						</div>
						<div class="font-size12 font-color-FF9900 margin-t-4" style="line-height: normal;"
							v-if="limitTypeArr1[index]||item.rule.fMaxSize">
							* 提示：
							<span>格式为{{item.rule.fFileType}}</span>
							<span v-if="item.rule.fMaxSize">,大小不超过{{ item.rule.fMaxSize }}MB</span>
						</div>
					</el-form-item>
					<el-form-item label="法人姓名" prop="fMenberName">
						<el-input v-model.trim="bindingform.fMenberName" placeholder="请输入成员名称"
							:disabled="bindingform.fMenberFrontFilePath ? false : true"></el-input>
					</el-form-item>
					<el-form-item label="身份证号码" prop="fMenberCardNumber">
						<el-input v-model="bindingform.fMenberCardNumber" placeholder="请输入身份证号码"
							:disabled="bindingform.fMenberFrontFilePath ? false : true"></el-input>
					</el-form-item>
					<el-form-item label="证件开始日期" prop="fMenberCardBeginDate">
						<el-input v-model="bindingform.fMenberCardBeginDate" placeholder="请输入证件开始日期 例: 1999-12-31"
							:disabled="bindingform.fMenberOppositeFilePath ? false : true"></el-input>
					</el-form-item>
					<el-form-item label="证件有效期" prop="fMenberCardEndDate">
						<el-input v-model="bindingform.fMenberCardEndDate" placeholder="请输入证件有效期 例: 1999-12-31 或 长期"
							:disabled="bindingform.fMenberOppositeFilePath ? false : true"></el-input>
					</el-form-item>
					<el-form-item label="联系电话" prop="fMenberPhone">
						<el-input v-model.trim="bindingform.fMenberPhone" placeholder="请输入联系电话"></el-input>
					</el-form-item>
					<el-form-item label="联系地址" prop="fMenberAdress">
						<el-input v-model.trim="bindingform.fMenberAdress" placeholder="请输入联系地址"></el-input>
					</el-form-item>
				</div>

				<div class="itemBoxtit">受益人信息</div>
				<div class="itemBox">
					<div class="itemColor"></div>
					<!-- <el-form-item v-if="item.rule.fAnnexTypeID == '213'" v-for="(item, index) in uploadRule" :key="index" :label="item.rule.fRuleName"  class='testClass' :required="item.rule.fIsNecessary == 1 ? true : false">
						<div class="padding-10 border-F2F2F2" style="margin-right: 35px;">
							<UploadImgList :rules="item.rule" :ISconfirm="ISconfirm" :uploadToken="uploadToken1[index]" class="uploadimglist"
								:limit="1" :accept="limitTypeArr1[index]" v-model="imgListName[index]" uploadType="image" @success="uploadSuccess1"/>
						</div>
						<div class="font-size12 font-color-FF9900 margin-t-4" style="line-height: normal;"
							v-if="limitTypeArr1[index]||item.rule.fMaxSize">
							* 提示：
							<span>格式为{{item.rule.fFileType}}</span>
							<span v-if="item.rule.fMaxSize">,大小不超过{{ item.rule.fMaxSize }}MB</span>
						</div>
					</el-form-item>
					<el-form-item v-if="item.rule.fAnnexTypeID == '214'" v-for="(item, index) in uploadRule" :key="index" :label="item.rule.fRuleName"  class='testClass' :required="item.rule.fIsNecessary == 1 ? true : false">
						<div class="padding-10 border-F2F2F2" style="margin-right: 35px;">
							<UploadImgList :rules="item.rule" :ISconfirm="ISconfirm" :uploadToken="uploadToken1[index]" class="uploadimglist"
								:limit="1" :accept="limitTypeArr1[index]" v-model="imgListName[index]" uploadType="image" @success="uploadSuccess1"/>
						</div>
						<div class="font-size12 font-color-FF9900 margin-t-4" style="line-height: normal;"
							v-if="limitTypeArr1[index]||item.rule.fMaxSize">
							* 提示：
							<span>格式为{{item.rule.fFileType}}</span>
							<span v-if="item.rule.fMaxSize">,大小不超过{{ item.rule.fMaxSize }}MB</span>
						</div>
					</el-form-item> -->
					<el-form-item label="受益人" prop="fMenberSyName">
						<el-input v-model.trim="bindingform.fMenberSyName" placeholder="请输入受益人"></el-input>
						<p style="color: #FF9900; line-height: 16px; padding-right: 35px;">
							*根据银行要求需填报受益人，现系统默认受益人为法人，如与实际不符，请自行编辑更改。</p>
					</el-form-item>
					<el-form-item label="身份证号码" prop="fMenberSyCardNumber">
						<el-input v-model="bindingform.fMenberSyCardNumber" placeholder="请输入身份证号码"></el-input>
					</el-form-item>
					<el-form-item label="证件开始日期" prop="fMenberSyCardBeginDate">
						<el-input v-model="bindingform.fMenberSyCardBeginDate"
							placeholder="请输入证件开始日期 例: 1999-12-31"></el-input>
					</el-form-item>
					<el-form-item label="证件有效期" prop="fMenberSyCardEndDate">
						<el-input v-model="bindingform.fMenberSyCardEndDate"
							placeholder="请输入证件有效期 例: 1999-12-31 或 长期"></el-input>
					</el-form-item>
					<el-form-item label="联系电话" prop="fMenberSyPhone">
						<el-input v-model.trim="bindingform.fMenberSyPhone" placeholder="请输入联系电话"></el-input>
					</el-form-item>
					<el-form-item label="联系地址" prop="fMenberSyAdress">
						<el-input v-model.trim="bindingform.fMenberSyAdress" placeholder="请输入联系地址"></el-input>
					</el-form-item>
					<el-form-item label="受益/控制方式" prop="fMenberSyType">
						<el-select v-model="bindingform.fMenberSyType" placeholder="请选择受益方式">
							<el-option v-for="item in fMenberSyTypeS" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="比例(%)" prop="fMenberSyRate">
						<el-input v-model.trim="bindingform.fMenberSyRate" placeholder="请输入比例"></el-input>
					</el-form-item>
					<p style="padding: 0 37px 10px 40px; color: #FF9900; font-size: 12px;">{{'* 提示：' + JYTnotice}}</p>
				</div>
				<el-form-item label="" id="setform" prop="radio">
					<div style="line-height: 20px !important;">
						<el-checkbox-group v-model="bindingform.radio">
							<el-checkbox label="我已阅读并同意"></el-checkbox>
						</el-checkbox-group>
						<span v-for="(item,index) in zftAgreement" :key='index' style="display: inline-block;line-height: 20px;">
							<span style="cursor: pointer;" class="color-theme font-size12 lh21" @click="showAgreeDia(item.fAgreementHistID)">《{{item.fTitle}}》</span>
						</span>
					</div>
				</el-form-item>
				<el-form-item>
					<el-button class="background-color-theme" @click="save" :disabled="buttonloading"
						:loading='buttonloading'>{{fAccountname=='开通交易通'?'申请开通':'确认绑定'}}</el-button>
				</el-form-item>
			</el-form>
			<div class="bg-box"></div>
		</div>
		<agreementDialog :iContents='iContent' ref="areDialog"></agreementDialog>
		<!-- <BranchSelection v-if="BranchSelection" :showModel='BranchSelection' :id="fBankCode" @change="branchChange"></BranchSelection> -->
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	// import Graphic from '@/components/Graphic';
	import agreementDialog from '@/components/agreementDialog';
	// import BranchSelection from './BranchSelection';
	import UploadImgList from '@/components/upload/UploadImgList';
	import IdentityCodeValid from '@/utils/checkIdent';
	export default {
		data() {
			let validateIDCard = (rule, value, callback) => {
				if (!IdentityCodeValid(value)) {
					callback(new Error('请输入正确的身份证号'));
				} else {
					callback();
				}
			};
			// 验证手机号的校验规则
			let checkMobile = (rule, value, callback) => {
				console.log(value)
				// 验证手机号的正则表达式
				const regMobile = /^1[3-9]\d{9}$|^0\d{2,3}-\d{7,8}$/;
				if (regMobile.test(value)) {
					// 合法的手机号
					return callback();
				}
				// 不合法
				callback(new Error("请输入正确的手机号"));
			};
			// 比例范围校验
			let MenberSyRateMax = (rule, value, callback) => {
				console.log(value)
				if (value > 0 && value <= 100) {
					// 合法的手机号
					return callback();
				}
				// 不合法
				callback(new Error("比例必须大于0小于等于100"));
			};
			return {
				imgListName: [],
				ISconfirm: true,
				uploadToken: [], //上传图片token
				uploadToken1: [], //上传图片token
				limitTypeArr: [],
				limitTypeArr1: [],
				uploadRule: [],
				fOrgTypes: [], // 机构类型列表
				fOwnerships: [], // 所有制性质列表
				fEnterpriseSizes: [ // 企业规模
					{
						label: '大型',
						value: 'A'
					},
					{
						label: '中型',
						value: 'B'
					},
					{
						label: '中小型',
						value: 'C'
					},
					{
						label: '小型',
						value: 'D'
					},
					{
						label: '微小型',
						value: 'E'
					},
				],
				fMenberSyTypeS: [ // 受益方式
					{
						label: '股权',
						value: '1'
					},
					{
						label: '投票权',
						value: '2'
					},
					{
						label: '财务',
						value: '3'
					},
					{
						label: '人事',
						value: '4'
					},
					{
						label: '协议',
						value: '5'
					},
					{
						label: '权益',
						value: '6'
					},
					{
						label: '事实控制',
						value: '7'
					},
					{
						label: '受益权',
						value: '8'
					},
					{
						label: '其它',
						value: '0'
					},
				],
				imgList: [], //显示图片
				bankImg: '',
				bankName: '',
				fCardMediaTypeName: '',
				fCardMediaType: '',
				message: '',
				bindingform: {
					fCustomerID: '', // 企业id
					fCustomerName: '', // 企业名称
					fIdentityCode: '', // 统一社会信用代码
					fAddress: '', // 企业注册地址
					fCompanyAdress: '', // 企业实际办公地址
					fCompanyPhone: '', // 企业联系电话
					fIsCredit: 0, // 是否我行贷款客户
					fIsPublicCompany: 0, // 是否上市公司
					fOrgTypeID: 1, // 机构类型
					fOwnershipID: '', // 所有制性质
					fEnterpriseSize: '', // 企业规模
					fIdentityFilePath: '', // 营业执照路径

					fAccount: '', // 绑定账户

					fMenberName: '', // 成员姓名
					fMenberCardNumber: '', // 证件号
					fMenberCardEndDate: '', // 证件有效期
					fMenberCardBeginDate: '', // 证件开始日期
					fMenberPhone: '', // 联系电话
					fMenberAdress: '', // 联系地址
					fMenberFrontFilePath: '', // 法人身份证正面
					fMenberOppositeFilePath: '', // 法人身份证背面

					fMenberSyName: '', // 受益人
					fMenberSyCardNumber: '', // 身份证
					fMenberSyCardBeginDate: '', // 身份证开始日期
					fMenberSyCardEndDate: '', // 身份证有效期
					fMenberSyPhone: '', // 联系电话
					fMenberSyAdress: '', // 地址
					fMenberSyType: '', // 受益方式
					fMenberSyRate: '', // 比例
					radio: false, //协议的按钮
				},
				bankImg: '',
				bankName: '',
				fCardMediaTypeName: '',
				fCardMediaType: 0,
				message: '',

				noticeShow: false,
				iContent: '',
				fAccountname: '',
				zftAgreement: [],
				dialogFormVisible: false,
				formLabelWidth: "80px",
				loading: true,
				innerVisibles: false,
				title: "", //标题
				type: 1, //1 中金,2 账服通
				userinfo: {}, //用户信息
				bankList: [], //开户行
				msg: false,
				BranchSelection: false,
				buttonloading: false,
				fBankCode: '',
				rules: {
					fCustomerName: [{
						required: true,
						message: "请输入公司名称",
						trigger: "change",
					}],
					fIdentityCode: [{
						required: true,
						message: "请输入统一社会信用代码",
						trigger: "change",
					}],
					fAddress: [{
						required: true,
						message: "请输入企业注册地址",
						trigger: "change",
					}],
					fCompanyAdress: [{
						required: true,
						message: "请输入实际办公地址",
						trigger: "change",
					}],
					fCompanyPhone: [{
							required: true,
							message: "请输入企业联系电话",
							trigger: "change",
						},
						{
							validator: checkMobile,
							trigger: "blur",
						},
					],
					fOrgTypeID: [{
						required: true,
						message: "请选择机构类型",
						trigger: "change",
					}],
					fOwnershipID: [{
						required: true,
						message: "请选择所有制性质",
						trigger: "change",
					}],
					fEnterpriseSize: [{
						required: true,
						message: "请选择企业规模",
						trigger: "change",
					}],
					fMenberName: [{
						required: true,
						message: "请输入成员名称",
						trigger: "change",
					}],
					fMenberCardNumber: [{
							required: true,
							message: "请输入身份证号",
							trigger: "change",
						},
						{
							validator: validateIDCard,
							trigger: "blur"
						}
					],
					fMenberCardEndDate: [{
							required: true,
							message: "请选择证件有效期",
							trigger: "change",
						},
						{
							validator: (rule, value, callback) => {
								if(value == '长期') {
									callback()
								} else {
									const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
									if (!dateRegex.test(value)) {
										callback(new Error('请输入有效的日期 (格式: yyyy-MM-dd)'));
									} else {
										callback();
									}
								}
							},
							trigger: 'blur'
						}
					],
					fMenberCardBeginDate: [{
						required: true,
						message: "请选择证件开始日期",
						trigger: "change",
					},
					{
						validator: (rule, value, callback) => {
							const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
							if (!dateRegex.test(value)) {
								callback(new Error('请输入有效的日期 (格式: yyyy-MM-dd)'));
							} else {
								callback();
							}
						},
						trigger: 'blur'
					}],
					fMenberPhone: [{
							required: true,
							message: "请输入联系电话",
							trigger: "change",
						},
						{
							validator: checkMobile,
							trigger: "blur",
						},
					],
					fMenberAdress: [{
						required: true,
						message: "请输入联系地址",
						trigger: "change",
					}],

					fMenberSyName: [{
						required: true,
						message: "请输入受益人",
						trigger: "change",
					}],
					fMenberSyCardNumber: [{
							required: true,
							message: "请输入身份证号",
							trigger: "change",
						},
						{
							validator: validateIDCard,
							trigger: "blur"
						}
					],
					fMenberSyCardEndDate: [{
						required: true,
						message: "请选择证件有效期",
						trigger: "change",
					},
					{
						validator: (rule, value, callback) => {
							if(value == '长期') {
								callback()
							} else {
								const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
								if (!dateRegex.test(value)) {
									callback(new Error('请输入有效的日期 (格式: yyyy-MM-dd)'));
								} else {
									callback();
								}
							}
						},
						trigger: 'blur'
					}],
					fMenberSyCardBeginDate: [{
						required: true,
						message: "请选择证件开始日期",
						trigger: "change",
					},
					{
						validator: (rule, value, callback) => {
							const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
							if (!dateRegex.test(value)) {
								callback(new Error('请输入有效的日期 (格式: yyyy-MM-dd)'));
							} else {
								callback();
							}
						},
						trigger: 'blur'
					}],
					fMenberSyPhone: [{
							required: true,
							message: "请输入联系电话",
							trigger: "change",
						},
						{
							validator: checkMobile,
							trigger: "blur",
						},
					],
					fMenberSyAdress: [{
						required: true,
						message: "请输入联系地址",
						trigger: "change",
					}],
					fMenberSyType: [{
						required: true,
						message: "请选择受益方式",
						trigger: "change",
					}],
					fMenberSyRate: [{
							required: true,
							message: "请输入比例",
							trigger: "change",
						},
						{
							validator: MenberSyRateMax,
							trigger: "blur",
						}
					],

					fAccount: [{
						required: true,
						message: "请输入银行卡号",
						trigger: "blur"
					}, {
						min: 12,
						message: '银行账号不少于12位',
						trigger: 'blur'
					}],
					radio: [{
						required: true,
						validator: (rule, value, callback) => {
							if (value != true) {
								callback(new Error('请阅读并同意注册协议'));
							} else {
								callback();
							}
						},
						trigger: 'change'
					}]

				},

				files: [],
				JYTnotice: '',
				annexServerUrl: ''
			};
		},
		computed: {
			...mapGetters(["getThemeName"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
			...mapGetters(["getUserInfo"]),
		},
		components: {
			agreementDialog,
			// Graphic,
			// BranchSelection
			UploadImgList
		},
		created() {
			// this.ruleForm = {};
			this.title = this.$route.query.title;
			this.type = this.$route.query.type
			this.fAccountname = this.$route.query.fAccountname;

			this.getImgUrl()
			this.getFOrgTypes()
			this.getFOwnerships()
			this.getJYTnotice()
			this.loading = false
		},
		mounted() {
			this.getReadAgreement();
		},
		methods: {
			closeNoticeShows() {
				this.noticeShow = false;
			},
			shows() {
				this.noticeShow = true;
			},
			showAgreeDia(a) {
				this.noticeShow = true;
				this.iContent = a;
				this.$refs.areDialog.show();
			},
			//获取交易通协议列表
			getReadAgreement() {
				this.ApiRequestPostNOMess('api/mall/ebbase/agreement-history/get-list-by-jyt-apply', {}).then(
					(result) => {
						if (result.obj) {
							this.zftAgreement = result.obj.items
						}
					},
					(rej) => {}
				);
			},
			// 获取受益人信息提示  api/mall/ebbase/para-value/get-jyt-notice
			getJYTnotice() {
				this.ApiRequestPost("api/mall/ebbase/para-value/get-jyt-notice", {}).then(
					(res) => {
						console.log('result', res)
						this.JYTnotice = res
					},
					(rej) => {}
				);
			},
			// 获取机构类型
			getFOrgTypes() {
				this.ApiRequestPost("api/mall/payment/jyt-org-type/get-list-enable-drop", {}).then(
					(result) => {
						this.fOrgTypes = result.obj
					},
					(rej) => {}
				);
			},
			// 获取所有制性质
			getFOwnerships() {
				this.ApiRequestPost("api/mall/payment/jyt-ownership/get-list-enable-drop", {}).then(
					(result) => {
						this.fOwnerships = result.obj
					},
					(rej) => {}
				);
			},
			getImgUrl() {
				//上传图片前缀
				this.ApiRequestPostNOMess(
					"/api/mall/ebbase/para-value/get-config"
				).then(
					(result) => {
						this.annexServerUrl = result.obj.annexServerUrl
						this.getInfo();
					},
					(rej) => {
						this.getInfo();
					}
				)
			},
			//   获取用户信息
			getInfo() {
				this.ApiRequestPost("api/mall/ebcustomer/baseinfo/get-by-current-user-customer-base-info-full", {}).then(
					(result) => {
						console.log(result, "00000000000");
						result.obj.files.forEach(item => {
							if (item.fIdentificationTypeName == '营业执照') {
								// this.imgList = [item]
								this.bindingform.fIdentityFilePath = item.fPath
							} else if (item.fAnnexTypeID == '213') {
								this.bindingform.fMenberFrontFilePath = item.fPath
								if (item.fPath) {
									console.log(11111111111,item.fPath)
									
									this.uploadSuccess1([{fPath: item.fPath, fAnnexTypeID: '213'}], this.annexServerUrl)
								}
							} else if (item.fAnnexTypeID == '214') {
								this.bindingform.fMenberOppositeFilePath = item.fPath
								if (item.fPath) {
									this.uploadSuccess1([{fPath: item.fPath, fAnnexTypeID: '214'}], this.annexServerUrl)
								}
							}
						})
						this.bindingform.fCustomerID = result.obj.fCustomerID
						this.bindingform.fCustomerName = result.obj.fCustomerName
						this.bindingform.fIdentityCode = result.obj.fIdentityCode
						this.bindingform.fAddress = result.obj.fAddress
						this.bindingform.fCompanyAdress = result.obj.fAddress
						this.bindingform.fCompanyPhone = result.obj.fPhone
						this.bindingform.fMenberName = this.bindingform.fMenberSyName = result.obj.fLegalPerson
						this.bindingform.fMenberCardNumber = this.bindingform.fMenberSyCardNumber = result.obj
							.fLegalPersonCard
						result.obj.files.forEach(item => {
							if (item.fAnnexTypeID == '502' || item.fAnnexTypeID == '213' || item
								.fAnnexTypeID == '214') {
								this.files.push(item)
							}
						})
						this.getAdjustBillTypeAnnexRule()
					},
					(rej) => {}
				);
			},
			// 获取营业执照上传规则
			getAdjustBillTypeAnnexRule() {
				this.uploadRule = [] //这里请求前先	清除
				this.imgListName = [] //去除图片列表
				this.ApiRequestPostNOMess('api/mall/ebcustomer/adjust-bill/get-enterprise-adjust-bill-type-annex-rule', {
						"id": '2'
					})
					.then(res => {
						console.log('获取附件验证规则', res);
						res.forEach(item => {
							if (item.rule.fAnnexTypeID == "502") {
								this.uploadRule.push(item)
							}

						})
						let Token = {};
						let Type = [];
						this.uploadRule.forEach((item, index) => {
							Type[index] = item.rule.fFileType.split(',');
							Token.token = item.token;
							this.uploadToken.push(Token);
							console.log('this.uploadRule', this.uploadToken)
						});
						Type.forEach(item => {
							let imitTypeStr = ''
							item.forEach(item1 => {
								imitTypeStr = imitTypeStr + '.' + item1 + ','
							})
							this.limitTypeArr.push(imitTypeStr)
						})
						this.getAdjustBillTypeAnnexRule1()
					}, error => {});
			},
			// 获取身份证上传规则
			getAdjustBillTypeAnnexRule1() {
				this.ApiRequestPostNOMess('api/mall/ebcustomer/adjust-bill/get-perfect-data-rule', {
						"id": '2'
					})
					.then(res => {
						res.forEach(item => {
							this.uploadRule.push(item)
						})
						let Token = {};
						let Type = [];
						this.uploadRule.forEach((item, index) => {
							Type[index] = item.rule.fFileType.split(',');
							Token.token = item.token;
							this.uploadToken1.push(Token);
							console.log('this.uploadRule', this.uploadToken)
						});
						Type.forEach(item => {
							let imitTypeStr = ''
							item.forEach(item1 => {
								imitTypeStr = imitTypeStr + '.' + item1 + ','
							})
							this.limitTypeArr1.push(imitTypeStr)
						})
						let _this = this
						this.uploadRule.forEach((item, index) => {
							_this.files.forEach((item1, index1) => {
								if (item.rule.fAnnexTypeID == item1.fAnnexTypeID) {
									_this.imgListName[index] = [];
									_this.imgListName[index].push({
										fPath: item1.fPath,
										ruleId: item.rule.fRuleID
									})
								}
							});
						});
						this.formLoading = false;
					}, error => {
						this.formLoading = false;
					});
			},
			//上传成功识别身份证
			uploadSuccess1(e, url) {
				// console.log('上传成功', e, url);
				let param = {
					imageUrl: url + e[0].fPath
				}
				if (e[0].fAnnexTypeID == '213') {
					this.bindingform.fMenberFrontFilePath = e[0].fPath
				} else if (e[0].fAnnexTypeID == '214') {
					this.bindingform.fMenberOppositeFilePath = e[0].fPath
				}
				this.ApiRequestPost('api/mall/tencent-cloud/ocr-id-card-by-image-url', param)
					.then(res => {
						console.log(res);
						if (res.isSucceed) {
							// this.formData.realName = res.name
							// this.formData.IDCardNumber = res.idNum
							console.log(11111111111, e, url)
							if (e[0].fAnnexTypeID == '213') {
								if (res.idNum) {
									this.bindingform.fMenberSyName = this.bindingform.fMenberName = res.name
									this.bindingform.fMenberSyCardNumber = this.bindingform.fMenberCardNumber = res
										.idNum
								} else {
									this.$message('请上传身份证人面像');
								}
							} else if (e[0].fAnnexTypeID == '214') {
								if (!res.idNum) {
									let arr = res.validDate.split('-')
									if (arr[1] == '长期') {
										this.bindingform.fMenberSyCardEndDate = this.bindingform.fMenberCardEndDate =
											'长期'
									} else {
										let arr1 = arr[1].split('.')
										this.bindingform.fMenberSyCardEndDate = this.bindingform.fMenberCardEndDate =
											arr1.join('-')
									}
									let arr2 = arr[0].split('.')
									this.bindingform.fMenberSyCardBeginDate = this.bindingform.fMenberCardBeginDate =
										arr2.join('-')
									console.log(this.bindingform.fMenberCardEndDate)
								} else {
									this.$message('请上传身份证国徽面');
								}
							}
						} else {
							this.$message('身份证识别失败，请重新上传');

						}
						this.isUpload = true;
					}, error => {
						this.isUpload = true;
					});
			},

			//上传成功识别营业执照
			uploadSuccess(e, url) {
				// console.log('上传成功', e, url);
				if (e[0].fAnnexTypeID != '502') {
					return
				}
				let param = {
					imageUrl: url + e[0].fPath
				}
				this.bindingform.fIdentityFilePath = e[0].fPath
				this.ApiRequestPost('/api/mall/tencent-cloud/ocr-biz-license-by-image-url', param)
					.then(res => {
						console.log(res);
						if (res.isSucceed) {
							this.bindingform.fCustomerName = res.name
							this.bindingform.fAddress = res.address
							this.bindingform.fCompanyAdress = res.address
							this.bindingform.fIdentityCode = res.regNum
						} else {
							this.$message('营业执照识别失败，请重新上传');
						}
						this.isUpload = true;
					}, error => {
						this.isUpload = true;
					});
			},
			save() {
				for (let item of this.uploadRule) {
					// console.log(item.rule.fIsNecessary);
					let imageList = this.imgListName;
					if (item.rule.fIsNecessary == 1) {
						let flag = {}
						for (let item1 of imageList) {
							if (item1 && item1 != []) {
								flag = item1.find(findItem => findItem.ruleId == item.rule.fRuleID);
								// console.log(flag, 'flag');
								if (flag) {
									break;
								}
							}
						}
						flag = flag ? flag : {};
						if (!flag.ruleId) {
							this.$message({
								message: item.rule.fRuleName + '不能为空',
							});
							return
						}
					}
				}
				this.$refs.bindingform.validate((valid) => {
					console.log(this.bindingform)
					if (valid) {
						console.log(this.message)
						if (this.message !== '') {
							this.$message.warning(this.message)
							return
						} else {
							this.toSave()
						}
					} else {
						return false;
					}
				});

			},
			toSave() {
				this.bindingform.fOrgTypeID = this.bindingform.fOrgTypeID * 1
				this.bindingform.fOwnershipID = this.bindingform.fOwnershipID * 1

				if (this.bindingform.fMenberOppositeFilePath == '') {
					this.$message('请上传身份证国徽面，请重新上传');
					return
				}
				if (this.bindingform.fMenberFrontFilePath == '') {
					this.$message('请上传身份证人面像，请重新上传');
					return
				}
				if (this.bindingform.fMenberCardEndDate == '长期') {
					this.bindingform.fMenberCardEndDate = '9999-12-31'
				}
				if (this.bindingform.fMenberSyCardEndDate == '长期') {
					this.bindingform.fMenberSyCardEndDate = '9999-12-31'
				}
				if (this.bindingform.radio == false) {
				  return this.tipsInfo('请阅读并同意注册协议');
				}
				this.buttonloading = true
				console.log('this.bindingform', this.bindingform)
				this.ApiRequestPostNOMess('api/mall/ebcustomer/bank-account/create-jyt-account', this.bindingform).then(
					(result) => {
						console.log(result, "00000000000");
						this.buttonloading = false;
						if (result.obj.status == "1" || result.obj.status == "2") {
							if (this.$route.query.fAccountname != undefined) {
								this.$router.push({
									path: "/businessme/JYTwallet"
								});
							}
							this.$message({
								message: result.obj.message,
								type: 'success'
							});
						} else {
							if (result.obj.status == "3") {
								this.$router.push({
									path: "/businessme/JYTwallet"
								});
							} else {
								this.$message({
									message: result.obj.message,
									type: 'error'
								});
							}
							this.buttonloading = false;
						}
					},
					(rej) => {this.buttonloading = false;}
				).catch(err => {
					this.buttonloading = false;
				});

			}
		},
	};
</script>

<style lang="scss" scoped="scoped">
	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.background-color-theme {
		background-color: #0173FE;
		color: #FFFFFF;
	}

	/deep/ .tip {
		width: 390px;
		line-height: 16px;
	}

	.codeRlBox {
		height: 30px;
		line-height: 30px;
	}

	.icon-biaodan {
		color: #1c9dff;
	}

	/deep/ .el-form-item__label {
		padding-right: 19px;
	}

	/deep/ .el-input__inner {
		width: 380px;
		height: 32px;
		line-height: 32px;
		// padding: 0 10px;
		border: 1px solid #ccc !important;
	}

	.sendCode {
		margin-right: 40px;
	}

	.title {
		height: 20px;
		line-height: 20px;
		margin: 55px auto 16px;
		margin-left: 447px;
	}

	.contBox {
		overflow: hidden;
	}

	.demo-ruleForm {
		width: 570px;
		margin: 0px auto;
		margin-left: 200px;
		position: relative;
		z-index: 1;
	}

	.bg-box {
		position: relative;
		z-index: 0;
		width: 100%;
		height: 230px;
		background: url(../../../assets/imgs/u305.png) no-repeat;
		background-size: 911px 310px;
		background-position: right bottom;
		-webkit-filter: blur(1px);
		margin-top: -105px;
		opacity: 25%;
	}

	.el-button {
		width: 160px;
		height: 40px;
	}

	#setform>div {
		line-height: 20px !important;
	}

	.button-Branch {
		position: absolute;
		z-index: 2;
		top: 5px;
		left: 310px;
		width: 70px;
		height: 30px;
		line-height: 30px;
		text-align: center;
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
	}

	.bankbox {
		cursor: no-drop;
	}

	.debitcard {
		display: inline-block;
		color: #fff;
		background-color: #0164ff;
		padding: 0px 8px;
		border-radius: 5px;
		margin-left: 10px;
		line-height: 25px;
		font-size: 14px;
	}

	.creditcard {
		display: inline-block;
		color: #fff;
		background-color: #ff575a;
		padding: 0px 8px;
		border-radius: 5px;
		margin-left: 10px;
		line-height: 25px;
		font-size: 14px;
	}

	.imglistBox {
		width: 100%;
		margin: 10px 10px;
		margin-left: 212px;
		margin-bottom: 10px
	}

	.itemBox {
		// padding: 10px;
		border: 2px solid #0164ff;
		border-radius: 8px;
		margin-bottom: 20px;
	}

	.itemBoxtit {
		font-size: 15px;
		font-weight: 700;
		color: #000;
		line-height: 30px;
	}

	.itemColor {
		height: 30px;
		line-height: 30px;
		padding-left: 20px;
		color: red;
		background: rgba(1, 100, 255, 0.3);
		margin-bottom: 10px;
	}
	//协议复选框样式
	::v-deep .el-checkbox-group {
		width: fit-content !important;
		display: inline !important;
	}
</style>